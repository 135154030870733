import React, { useMemo } from "react";
import { useId } from "@mantine/hooks";

import { InputFieldProps } from "./input-field.interface";
import { FormField } from "components/atoms/form-field/form-field.component";
import { Input } from "components/atoms/input/input.component";
import { Tooltip } from "components/atoms/tooltip/tooltip.component";
import { Icon } from "../icon/icon.component";
import { Icons } from "../icon/icon.interface";

export const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
    (
        {
            testId,
            label,
            required,
            errorMessage,
            footer,
            showErrorAsTooltip,
            className,
            disabled,
            mask,
            tooltip,
            ...props
        },
        ref,
    ) => {
        const id = useId();

        const rightSection = useMemo(() => {
            if (showErrorAsTooltip && errorMessage) {
                return (
                    <Tooltip
                        message={errorMessage}
                        svgComponent={<Icon name={Icons.errorSvg} />}
                    />
                );
            }

            if (disabled) {
                return <Icon name={Icons.lockSvg} />;
            }

            return null;
        }, [disabled, errorMessage, showErrorAsTooltip]);

        return (
            <FormField
                id={id}
                label={label}
                required={required}
                errorMessage={showErrorAsTooltip ? null : errorMessage}
                footer={footer}
                className={className}
                tooltip={tooltip}
                testId={testId}
            >
                <Input
                    data-testid={testId}
                    ref={ref}
                    id={id}
                    error={!!errorMessage}
                    disabled={disabled}
                    rightSection={rightSection}
                    mask={mask}
                    {...props}
                />
            </FormField>
        );
    },
);

InputField.displayName = "InputField";
